@use 'styles/config' as *;

.playButton {
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute 0;
  perspective: 10rem;

  &__button {
    @include reset-button;

    position: relative;

    width: 25%;
    max-width: 10rem;
    min-width: 6rem;

    background: rgba(#232323, 0.6);
    border-radius: 50%;

    transition: transform 300ms;
    cursor: pointer;
    transform: translate3d(0.1rem, 0, 0);

    &:hover {
      transform: translate3d(0.1rem, 0, 1rem);
    }

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 0;
      padding-bottom: 100%;
    }

    &::before {
      content: '';
      display: block;

      position: absolute;
      top: 50%;
      left: 50%;

      margin: -1.1rem 0 0 -0.4rem;

      border: 1.2rem solid transparent;
      border-width: 1.1rem 1.3rem;
      border-left-color: brand();

      :global(.almatica) & {
        border-left-color: #fff;
      }
    }
  }

  &__container {
    @include container;
  }
}
