@use 'styles/config' as *;
@use "sass:math";

.content {
  display: block;

  &__segment {
    @include segment();
  }

  &__container {
    @include container();
  }

  &__row {
    @include grid-row();
  }

  &__col {
    @include grid-col();
  }
}

.item {
  margin-bottom: 12rem;

  &__layout {
    @include grid-row();
  }

  &.reverse &__layout,
  &:nth-child(2n) &__layout {
    @include grid-row(rtl);
  }

  &.reverse:nth-child(2n) &__layout {
    @include grid-row(ltr);
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__row {
    @include grid-row();
  }

  &__imageCol {
    @include grid-col();

    @media (min-width: $min-720) {
      @include grid-col(5);
    }
  }

  &__play {
    z-index: 2;
  }

  &__video {
    z-index: 1;
  }

  &__figure {
    position: relative;
    margin-left: -$gutter;
    margin-right: -$gutter;

    @media (min-width: $min-720) {
      margin: auto;
    }

    @media (min-width: $min-960) {
      margin-left: -$gutter;
      margin-right: -$gutter;
    }
  }

  &__image {
    position: relative;
    max-height: 100%;

    @media (min-width: $min-480) {
      max-height: 60rem;
    }

    @media (min-width: $min-720) {
      max-height: none;
      margin: auto;
    }

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 0;
      padding-bottom: math.div(640, 560) * 100%;
    }
  }

  &__img {
    position: absolute 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__contentCol {
    @include grid-col();
    margin-bottom: $gutter * 3;
    align-self: center;

    @media (min-width: $min-720) {
      @include grid-col(6);
      @include grid-offset-right(1);
      align-self: center;
      margin-bottom: 0;
    }

    @media (min-width: $min-960) {
      @include grid-col(5);
      @include grid-offset-right(1);
      align-self: center;
    }
  }

  &.reverse &__contentCol,
  &:nth-child(2n) &__contentCol {
    @include grid-offset-right(0);
    @include grid-offset-left(1);
  }

  &.reverse:nth-child(2n) &__contentCol {
    @include grid-offset-right(1);
    @include grid-offset-left(0);
  }

  &__tagline {
    @include taglineWithLine;

    margin: 0 0 4rem;
  }

  &__title {
    @include h2;
    margin: 0 0 2rem;

    @media (min-width: $min-720) {
      margin: 0 0 4rem;
    }

    :global(body.dark) & {
      color: #fff;
    }
  }

  &__text {
    @include grid-col();

    @media (min-width: $min-960) {
      @include grid-col(percentage(math.div(3, 5)));
      @include grid-offset-left(percentage(math.div(1, 5)));
    }
  }

  &__copy {
    @include render-copy;
    color: #757575;

    @media (min-width: $min-960) {
      margin-right: -$gutter;
    }

    :global(body.brand) & {
      color: $color-font;
    }
  }

  &__buttons {
    margin-top: 2rem;

    @media (min-width: $min-720) {
      margin-top: 4rem;
    }

    &.inline {
      @include grid-col();

      @media (min-width: $min-720) {
        margin-top: 2rem;
      }

      @media (min-width: $min-960) {
        @include grid-col(percentage(math.div(3, 5)));
        @include grid-offset-left(percentage(math.div(1, 5)));
      }
    }
  }

  &__link {
    margin-top: 1rem;

    @media (min-width: $min-720) {
      margin-top: 2rem;
    }
  }
}
