@use 'styles/config' as *;
@use "sass:math";

.videoOverlay {
  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed 0;
  z-index: $z-overlay;

  // padding: $gutter;

  background: rgba(#000, 1);

  &__foo {
    width: 100%;
  }

  &__aspect {
    position: relative;
    padding-top: math.div(9, 16) * 100%;

    background: #000;
  }

  &__player {
    position: absolute 0;
  }

  &__container {
    @include container;
  }

  &__close {
    @include reset-button;

    position: absolute;
    z-index: 10;
    top: 0;
    right: 0;

    width: 10rem;
    height: 10rem;

    cursor: pointer;
    background: #fff;

    &::before {
      content: '';
      display: block;

      position: absolute;
      top: 50%;
      left: 50%;

      margin: -1.1rem 0 0 -2rem;

      border: 1.2rem solid transparent;
      border-width: 1.1rem 1.3rem;
      border-right-color: #000;
    }
  }
}
